:root {
  --active-step-color: #FFFFFF;
  --active-step-bg-color: #800020;
}

.place-order-bottom-container{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.receiver-contact-container, .sender-contact-container{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.contact-container{
  width: 48%;
}

.notice-container{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  height: 105px;
  background-color: black;
  color: #FFFFFF;
  padding-left: 10px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.notice{
  display: flex;
  align-items: center;
}

.note, .note-para{
  font-family: 'Inter';

}

.know-more a{
  display: block;
  width: 100%;
  text-align: center;
  color: #FFFFFF;
  font-family: 'Inter';
  text-decoration: none;
}

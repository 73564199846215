.service-container{
    background-color: #d4d4d4;
}
/* @media only screen and (min-width: 1361px) {
  .service-container  {
    width: 1360px;
  }
} */
@media (min-width: 1361px) {
  .service-container .header, .service-container .services, .service-container .sizeBox {
    width: 1360px;
    margin: 0 auto; 
  }
 
}

.service-container .header {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  height: 79vh;
}
.service-container .img-block{
    background-color: #800020;
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; 
}
.service-container .img-block .img-header1{
    width: 100%;
    height: 370px;
}
.service-container .img-block .img-header2{
    width: 100%;
    height: 420px;
}

.service-container .image1 {
  width: 200px;
  height: 100%;
  margin-top: -4px;
  object-fit: cover;
  margin-right: 100px;
}
.service-container .image2 {
  width: 315px;
  height: 100%;
  object-fit: cover;
  margin-right: 50px;
}

.service-container .text-block {
  width: 100%;
  margin-left: 30px;
  padding-right: 100px;
}

.service-container .text-block h1 {
  font-size: 2em;
  color: #800020;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 15px;
}

.service-container .text-block h2 {
  font-size: 1.2em;
  color:#464646;
  font-weight: 600;
}

.service-container .text-block p {
  font-size: 1em;
  color: gray;
}

.service-container .services {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.service-container .services h3 {
  margin-bottom: 20px;
}

.service-container .service-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 30px;
}

.service-container .service-item {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 20px;
  height: 200px;
  margin: 10px;
  flex: 1 1 calc(33% - 40px);
  box-sizing: border-box;
  max-width: calc(33% - 40px);
  text-align: center;
}
.service-container .service-item .item1{
  display: flex;
  margin-bottom: 10px;
  gap: 8px;
}
.service-container .service-item p{
    margin-top: 20px;
    color: #666c89;
}

.service-container .service-item h2 {
    color: #464558;
}
.service-container .text-justify-end {
    text-align: end;
    
}
.service-container .icon-img{
  width: 80px;
  height: 60px;
}
.service-container .text-justify-start {
    text-align: start;
    
}

.service-container .service-description {
  background-color: #333;
  color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 420px;
}
.service-container .service-description h3{
  font-size: 38px;
  font-weight: 500;
}

.service-container .service-description button {
  background-color: #fff;
  color: #000;
  border: none;
  margin-top: 10px;
  border-radius: 5px;
  padding: 10px 20px;
  margin-top: 30px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.service-container .signup {
  background-color: #800020;
  color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  height: 125px;
  gap: 10px;
}
.service-container .signup h3{
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 10px;
}

.service-container .signup button {
  background-color: #fff;
  color: #000;
  border: none;
  padding: 15px 20px;
  width: 170px;
  font-size: 15px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.service-container .service-feature {
    width: 100%;
    min-height: calc(100vh - 131px);
    background: linear-gradient(rgba(0, 0, 0, 0.61), rgba(0, 0, 0, 0.589)),
      url("./servicesimg/servicebg.png") no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    
  }
  .service-container .border1{
     position: absolute;
     border: 2px solid #fff;
     border-left: 0px;
     height: 547px;
     width: 100px;
     left: -15px;
     top: 85px;
  }
  .service-container .border2{
     position: absolute;
     border: 2px solid #fff;
     border-right: 0px;
     height: 547px;
     width: 100px;
     right: 0;
     top: 85px;
  }
  
  .service-container .service-feature .sizeBox {
    padding: 40px 0px;
    margin: 50px;
  }
  
  .service-container .service-feature .boxCont {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 50px;
    margin-top: 30px;
  }
  
  .service-container .service-feature .boxCont .box {
    position: relative;
    width: 30%;
    min-height: 130px;
    background-color: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 20px 40px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 2px;
    cursor: pointer;
    overflow: hidden;
    transition: color 0.3s ease;
  }
  
  .service-container .service-feature .boxCont .box::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 100%;
    background-color: #800020;
    z-index: 0;
    transition: top 0.3s ease;
  }
  
  .service-container .service-feature .boxCont .box:hover::before {
    top: 0;
  }
  
  .service-container .service-feature .boxCont .box:hover {
    color: #fff;
  }
  
  .service-container .service-feature .boxCont .box * {
    position: relative;
    z-index: 1; 
  }
  .service-container .service-feature .boxCont .box{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .service-container .img-box{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 50%;
    background-color: #800020;
    border:2px solid #fff;
  }
  .service-container .service-feature .boxCont .box img {
    width: 30px;
    height: 30px;
  }
  .service-container .service-feature .boxCont .box h3{
    font-size: 25px;
    /* color: #464558; */
  }
  
  
  .service-container .service-feature .boxCont .box p {
    font-size: 1rem;
    font-weight: 500;
    margin: 10px 5px;
  }
  /* last box  */
  .service-container .service-work{
    height: 500px;
    /* max-width: 1300px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    
  }
  .service-container .service-work h2{
    text-align: center;
    font-size: 35px;
    font-weight: 600;
    margin: 30px 0;
  }
  .service-container .service-work img{
    /* width: 100%; */
    height: 360px;
    object-fit: cover;
  }
  .service-container .service-work button{
    background-color: #fff;
  color: #000;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }
  .service-container .work-detail {
    display: flex;
    justify-content: center; 
    align-items: center;
    margin: 40px 10rem;
    max-width: 980px;

  }
  .service-container .work-detail div:nth-child(2){
    display: flex;
    flex-direction: column;
    background-color: #800020;
    height: 364px;
    align-items: center;
    justify-content: center;
  }
  .service-container .work-detail div:nth-child(2) p{
    color: #ffffff;
    padding: 30px 50px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
  }
.service-container .img-header2 h2{
    display: none;
}


  @media only screen and (max-width: 847px) {
    .service-container .service-feature .boxCont {
      gap: 30px;
    }
  
    .service-container .service-feature .boxCont .box div {
      flex-direction: column;
    }
  
    .service-container .service-feature .boxCont .box {
      width: 85%;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 10px;
    }
  }

  @media only screen and (max-width: 1150px){
    .service-container .img-block .img-header1{
        display: none;
    }
    .service-container .services{
        flex-direction: column-reverse;
    }
    .service-container .work-detail{
        margin: 40px 2rem;
    }

  }
  @media only screen and (max-width: 850px){
    .service-container .service-item{
        flex: 1 1 50%;
    }
    .service-container .service-item {
        flex: 1 1 calc(50% - 40px);
        box-sizing: border-box;
        max-width: calc(50% - 40px);
      }
      .service-container .service-work{
        height: 900px;
      }
    .service-container .work-detail{
        flex-direction: column;
    }
    .service-container .work-detail div:nth-child(2){
        max-width: 488px;
    }
    .service-container .signup button{
        padding: 8px 20px;
    }
    .service-container .border1 , .service-container .border2{
      display: none;
    }
    
  }
  @media only screen and (max-width: 600px){
    .service-container .header{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 94vh;
    } 
    .service-container .img-block{
     background-color: #800020;
    width: 100%;
    height: 600px;
    
    }
    .service-container .text-block {
        width: 100%;
        text-align: center;
        margin-left: 0px;
        margin: 0 15px;
        padding-right: 0px;
        margin-bottom: 15px;
        padding: 15px 0;
      }
      .service-container .img-block .img-header2{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .service-container .image2{
        margin-right: 0;
        margin-top: 35px;
        width: 90%;
        height: 460px;
      }
      .service-container .text-block h2{
        display: none;
      }
      .service-container .text-block p{
        padding: 0 5px;
      }
      .service-container .img-header2 h2{
        display: block;
        color: #fff;
        margin-top: 15px;
        margin-bottom: 20px;
    }
    .service-container .service-description{
        height: 300px;
    }
    .service-container .signup{
      flex-direction: column;
      height: 220px;
    }
    .service-container .signup button{
      width: 170px;
    }

  }
  @media only screen and (max-width: 540px){
    .service-container .signup{
        flex-direction: column;
       
    }
    .service-container .signup h3{
        font-size: 24px;
    }
  }

  @media only screen and (max-width: 520px){
    .service-container .service-item {
      flex: 1 1 calc(50% - 40px);
      box-sizing: border-box;
      max-width: calc(50% - 20px);
      padding: 7px;
      height: 200px;
      margin: 7px;
    }
    .service-container .service-item h2 {
      font-size: 18px;
      padding: 0 0;
  }
  .service-container .icon-img{
    width: 60px;
    height: 50px;
  }
  .service-container .service-work img{
    width: 100%;
    height: 360px;
    object-fit: cover;
  }
  .service-container .work-detail div:nth-child(2) p {
    padding: 25px 15px
  }
}
  @media only screen and (max-width: 400px){
    .service-container .icon-img {
      width: 40px;
      height: 50px;
  }
  .service-container .service-item h2 {
    font-size: 17px;
    padding: 0 0;
}
.service-container .service-item p {
  margin-top: 20px;
  color: #666c89;
}
.service-container .service-feature .sizeBox {
  padding: 20px 0px;
  margin: 20px;
}
.service-container .work-detail {
  margin: 20px 1rem;
}
.service-container .service-feature .boxCont .box {
  width: 100%;
  justify-content: center;
}
}

:root {
  --active-step-color: #FFFFFF;
  --active-step-bg-color: #800020;
}

.place-order-bottom-container{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.parcel-size-container{
  display: flex;
  justify-content: space-between;
}

.size-container{
  width: 48%;
}
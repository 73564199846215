.shippment-detail-main{
  display: flex;
  flex-direction: column;
  align-items: center;
}


.shipment-status {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}


.shipment-status .outside-circle {
  border: 1px solid gray;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  z-index: 1;
}

.shipment-status .status-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: gray;
  z-index: 1;
}

.shipment-status .outside-circle.completed {
  border-color: #800020;
  background-color: #ffffff;
}

.shipment-status .status-circle.completed {
  background-color: #800020;
}

/* grid method  */

.shipment-status .status-details {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  position: relative;
  width: 100%;
  
}
.shipment-status .status-details .status-1{
  grid-column: span 2 / span 2;
  display: flex;
  flex-direction: column;
  text-align: end;
  justify-content: right;

}

.shipment-status .status-details .status-2{
  grid-column: span 1/ span 1;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
}

.shipment-status .status-details .status-3{
  grid-column: span 2 / span 2;
}

.shipment-status .status-details .status-location{
  display:-webkit-box;
  -webkit-line-clamp: 1; 
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.status-date,
.status-time,
.status-process,
.status-location {
  margin: 5px 0;
}
.shipment-status .status-2 .status-line{
  position: relative;
  z-index: 0;
}


.shipment-status .status-2 .status-line::after{
  content: '';
  position: absolute;
  top: -41px; 
  left: 15px; 
  width: 1.5px;
  height: 41px; 
  background-color: gray;
  z-index: 0;
}

/* .shipment-status .status-details .status-2 .status-line:first-child::after {
  display: none;
} */
.shipment-status .status-details:first-child .status-line::after {
  display: none;
}

.shipment-status .status-line.completed+.status-line::after {
  background-color: #800020;
}




/* animation for glow */

@keyframes pulse {
  0% {
      box-shadow: 0 0 5px rgba(128, 0, 32, 0.5);
  }

  50% {
      box-shadow: 0 0 10px rgba(128, 0, 32, 1);
  }

  100% {
      box-shadow: 0 0 5px rgba(128, 0, 32, 0.5);
  }
}


.last-active-index {
  animation: pulse 2s infinite;
}

@media screen and (max-width: 370px){
  .shipment-status .status-details .status-date{
    display:-webkit-box;
    -webkit-line-clamp: 1; 
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media screen and (max-width: 355px){
  .shipment-status .status-2 .status-line::after {
    content: '';
    position: absolute;
    top: -60px;
    left: 15px;
    width: 1.5px;
    height: 62px;
    background-color: gray;
    z-index: 0;
}
}

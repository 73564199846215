.profilepage-main{
    background-color: #d4d4d4;
}
.profilepage-main .profile-heading h1{
  font-size: 35px;
}

.profilepage-main .Profilepage-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
.profilepage-main .Profilepage-container header {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
  }
  
.profilepage-main .Profilepage-container .icon {
    margin-left: 20px;
    font-size: 20px;
    color: #800020;
    background-color: white;
    padding: 7px;
    border-radius: 50%;
    cursor: pointer;
  }
  
.profilepage-main .personal-details{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
.profilepage-main .edit-profile{
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 25px;
  font-weight: 600;
  cursor: pointer;
  color: #800020;
}
.profilepage-main .user-info {
    position: relative;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    flex: 1.5;
  }
.profilepage-main .user-details1{
  display: flex;
}
.profilepage-main .user-details1 div:nth-child(2){
  display: flex;
  flex-direction: column;
  line-height: 32px;
  padding-left: 30px;
}


.profilepage-main .user-details2{
  display: grid;
  grid-template-columns: 1fr 1fr;
  line-height: 32px;
  margin-top: 20px;
  justify-content: left;
}
.profilepage-main .address-container {
  grid-column: span 2; 
  display: flex;
  flex-direction: column; 
}
  
.profilepage-main .profile-pic {
    border-radius: 50%;
    width: 100px;
    /* float: left; */
    height: 100px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  }
  
.profilepage-main .feedback {
    background-color: #800020;
    color: #fff;
    padding: 20px;
    border-radius: 10px;
    margin-left: 20px;
    min-height: 300px;
    flex: 1;
  }
  
.profilepage-main .shipping-history {
    background-color: #fff;
    margin-top: 20px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
.profilepage-main .shipping-item {
    background-color: #e0e0e0;
    height: 100px;
    margin-top: 10px;
  }
.profilepage-main .name-tag2{
  display: none;
}

/* responsive css  */

@media screen and (max-width: 1200px) {
  
}
@media screen and (max-width: 800px) {
  .profilepage-main .personal-details{
    flex-direction: column;
  } 
  .profilepage-main .feedback{
    margin-top: 20px;
    margin-left: 0;
  }
}
@media screen and (max-width: 450px) {
  .profilepage-main .user-details1{
    flex-direction: column;
  }
  .profilepage-main .profile-heading h1{
    font-size: 20px;
    font-weight: 600;
  }
  .profilepage-main .user-details1 div:nth-child(2){
    padding-left: 0;
  }
  .profilepage-main .Profilepage-container header div:nth-child(2) {
    display: flex;
  }
  .profilepage-main .user-details2{
    display: flex;
    flex-direction: column;
    line-height: 32px;
    margin-top: 0;
  }
  .profilepage-main .name-tag{
    display: none;
  }
  .profilepage-main .name-tag2{
    display: block;
  }
  .profilepage-main .profilepic-name{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
  .profilepage-main .profilepic-name h2{
     font-size: 20px;
  }
}

.faq-container .container {
min-width: 100%;
}

.faq-container .red-section {
  background: rgba(128, 0, 32, 1);
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.faq-container .container-guide .header {
  text-align: center;
  margin-bottom: 90px;
}

.faq-container .header h1,
.faq-container .header p  {
  color: white;
  padding: 10px;
  text-align: center;
}

.faq-container .card-container{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 70px;
  margin-top: 30px;
}

.faq-container .card {
  height: 17rem;
  width: 13%;
  margin-bottom: 20px;
  padding: 35px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  background-color: white;
  text-align: center;
}

.faq-container .card h2 {
  font-size: 1.4rem;
  height: 4.5rem;
}

.faq-container .card p {
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1.3;
}

.faq-container .card-section {
  padding: 30px;
  background-color: rgb(190, 188, 188);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


.faq-container .button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 30px;
}

.faq-container .container button {
  width: 150px;
  height: 38px;
  border: none;
  border-radius: 5px;
  background: rgba(128, 0, 32, 1);
  color: white;
  font-weight: 600;
  font-size: 1.1rem;
  
}

@media only screen and (min-width: 1361px) {
  .faq-container .card-container{
   width: 1360px;
  }
}


@media (max-width: 1450px) {
  .faq-container .card {
    width: 20%;
    padding: 20px;
  }
  .faq-container .card h2 {
    font-size: 1.7em;
  }
  .faq-container .card p {
    font-size: 1.1rem;
  }
}

@media (max-width: 1050px) {
  .faq-container .card {
    width: 30%;
    padding: 15px;
  }
  .faq-container .card h2 {
    font-size: 1.4rem;
    height: 4rem;
  }
  .faq-container.card p {
    font-size: 1rem;
  }
  .faq-container .card-container{
    gap: 40px;
  }
}

@media (max-width: 600px) {
  .faq-container .container-guide h1 {
    font-size: 1.5rem;
  }
  .faq-container .header p{
    font-size: 0.9rem;
  }
  .faq-container .card {
    width: 45%;
    height: 12rem;
    /* padding: 10px; */
  }
  .faq-container .card h2 {
    font-size: 1.4rem;
  }
  .faq-container .card p {
    font-size: 0.85rem;
  }
}

@media (max-width:450px) {
  .faq-container .card{
    width: 65%;
  }
  .faq-container .card h2{
    font-size: 1.1rem;
  }
  .faq-container .card p{
    font-size: 12px;
  }
}

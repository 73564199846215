.homeContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.homeContainer .sizeBox {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media only screen and (min-width: 1361px) {
  .homeContainer .sizeBox {
    width: 1360px;
  }
}

.homeContainer .firstHomeCont {
  width: 100%;
  min-height: calc(100vh - 131px);
  background: linear-gradient(rgba(0, 0, 0, 0.226), rgba(0, 0, 0, 0.192)),
    url("./Assets/landingBack.jpg") no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media only screen and (max-width: 1245px) {
  .homeContainer .firstHomeCont {
    width: 100%;
    min-height: calc(100vh - 131px);
    background: linear-gradient(rgba(0, 0, 0, 0.226), rgba(0, 0, 0, 0.192)),
      url("./Assets/landingBackResponsive.png") no-repeat;
    background-position: center;
    background-size: cover;
  }
}

.homeContainer .firstHomeCont .sizeBox {
  align-items: flex-start;
}

@media only screen and (max-width: 580px) {
  .homeContainer .firstHomeCont .sizeBox {
    align-items: center;
  }
}

.homeContainer .secondHomeCont {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 0;
  font-weight: 500;
}

.homeContainer .secondHomeCont p {
  font-size: 1.5rem;
  margin: 20px 0;
}

.homeContainer .secondHomeCont button {
  border-style: none;
  margin: 10px;
  padding: 11px 20px;
  font-size: 1rem;
  border-radius: 5px;
  color: #fff;
  background-color: #800020;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.homeContainer .secondHomeCont button:hover {
  background-color: #a0032a;
}

.homeContainer .thirdHomeCont {
  width: 100%;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 0;
  background-color: #d4d4d4;
}

.homeContainer .thirdHomeCont .boxCont {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 40px;
  margin: 50px 0;
}

.homeContainer .thirdHomeCont .boxCont .box {
  width: 230px;
  height: 170px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 1.1rem;
  font-weight: 500;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.homeContainer .thirdHomeCont .boxCont .box img {
  width: 55px;
  margin-top: 5px;
  margin-bottom: 20px;
}

.homeContainer .fourthHomeCont {
  width: 100%;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 0;
  font-weight: 500;
  background-color: #800020;
  color: #fff;
}

.homeContainer .fourthHomeCont button {
  margin: 10px;
  padding: 10px 15px;
  border-style: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 550;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.homeContainer .fourthHomeCont button:hover {
  background-color: #f3f3f3;
}

.homeContainer .fifthHomeCont {
  width: 100%;
  min-height: calc(100vh - 131px);
  background: linear-gradient(rgba(0, 0, 0, 0.13), rgba(0, 0, 0, 0.062)),
    url("./Assets/trackRoadBackImg.png") no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.homeContainer .fifthHomeCont .boxCont {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 40px;
  margin: 50px 0;
}

.homeContainer .fifthHomeCont .boxCont .box {
  width: 300px;
  min-height: 400px;
  padding: 20px;
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.homeContainer .fifthHomeCont .boxCont .box img {
  width: 50px;
  margin: 20px 0;
}

.homeContainer .fifthHomeCont .boxCont .box p {
  font-size: 1.4rem;
  font-weight: 600;
  color: #464558;
}

.homeContainer .fifthHomeCont .boxCont .box span {
  font-size: 0.9rem;
  margin: 15px 10px;
  text-align: center;
  color: #464558;
}

.homeContainer .fifthHomeCont .boxCont .box ul {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin: 25px 0;
  margin-bottom: 10px;
  font-size: 1rem;
  color: #464558;
}

.homeContainer .fifthHomeCont .boxCont .box ul li::marker {
  color: #800020;
}

.homeContainer .fifthHomeCont .boxCont .box button {
  width: 90%;
  margin-top: 20px;
  padding: 10px 0;
  border-style: none;
  font-size: 1.1rem;
  font-weight: 550;
  color: #800020;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #800020;
  cursor: pointer;
  transition: all 0.5s ease;
}

.homeContainer .fifthHomeCont .boxCont .box button:hover {
  background-color: #800020;
  color: #fff;
}

.homeContainer .sixthHomeCont {
  width: 100%;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.homeContainer .sixthHomeCont .box {
  width: 195px;
  height: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.homeContainer .sixthHomeCont .box span {
  font-size: 2.5rem;
  font-weight: 800;
  color: #800020;
  text-align: center;
}

.homeContainer .sixthHomeCont .box p {
  font-size: 1.1rem;
  font-weight: 600;
  margin-top: 15px;
  text-align: center;
}

.homeContainer .sixthHomeCont .box:nth-child(odd) {
  background-color: #be959f;
  position: relative;
  align-self: flex-start;
  top: 0px;
  border-bottom-left-radius: 48%;
  border-bottom-right-radius: 48%;
}

.homeContainer .sixthHomeCont .box:nth-child(even) {
  background-color: #aeabac;
  position: relative;
  bottom: 0px;
  align-self: flex-end;
  border-top-left-radius: 48%;
  border-top-right-radius: 48%;
}

.homeContainer .sixthHomeCont .sizeBox {
  flex-direction: row;
  gap: 50px;
  min-height: 300px;
}

@media only screen and (max-width: 785px) {
  .homeContainer .sixthHomeCont .box {
    width: 250px;
    height: 100px;
  }

  .homeContainer .sixthHomeCont .sizeBox {
    width: 100%;
    flex-direction: column;
    gap: 0px;
  }

  .homeContainer .sixthHomeCont .box:nth-child(odd) {
    top: 0px;
    left: 0px;
    border-radius: 0px;
    border-top-right-radius: 70px;
    border-bottom-right-radius: 70px;
    margin: 20px 0;
  }

  .homeContainer .sixthHomeCont .box:nth-child(even) {
    bottom: 0px;
    right: 0px;
    border-radius: 0px;
    border-top-left-radius: 70px;
    border-bottom-left-radius: 70px;
    margin: 20px 0;
  }
}

.homeContainer .seventhHomeCont {
  width: 100%;
  min-height: calc(100vh - 131px);
  background: linear-gradient(rgba(0, 0, 0, 0.61), rgba(0, 0, 0, 0.589)),
    url("./Assets/trackChooseBackImg.png") no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.homeContainer .seventhHomeCont .sizeBox {
  border: 2px solid #fff;
  padding: 40px 0px;
  margin: 50px;
}

.homeContainer .seventhHomeCont .boxCont {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 50px;
  margin-top: 30px;
}

.homeContainer .seventhHomeCont .boxCont .box {
  position: relative;
  width: 35%;
  min-height: 130px;
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 20px 40px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;
  transition: color 0.3s ease;
}

.homeContainer .seventhHomeCont .boxCont .box::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 100%;
  background-color: #800020;
  z-index: 0;
  transition: top 0.3s ease;
}

.homeContainer .seventhHomeCont .boxCont .box:hover::before {
  top: 0;
}

.homeContainer .seventhHomeCont .boxCont .box:hover {
  color: #fff;
}

.homeContainer .seventhHomeCont .boxCont .box * {
  position: relative;
  z-index: 1;
}

.homeContainer .seventhHomeCont .boxCont .box img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  background-color: #fff;
  border-radius: 50%;
  padding: 1px;
}

.homeContainer .seventhHomeCont .boxCont .box p {
  font-size: 1rem;
  font-weight: 500;
  margin: 10px 5px;
}

@media only screen and (max-width: 847px) {
  .homeContainer .seventhHomeCont .boxCont {
    gap: 30px;
  }

  .homeContainer .seventhHomeCont .boxCont .box h3 {
    flex-direction: column;
  }

  .homeContainer .seventhHomeCont .boxCont .box {
    width: 85%;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
  }
}

.homeContainer .eighthHomeCont {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  color: black;
  text-align: center;
}

.homeContainer .eighthHomeCont img {
  width: 140px;
  margin: 30px;
  cursor: pointer;
}

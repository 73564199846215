.profile-main {
  padding: 5rem 0;
  background-color: #d4d4d4;
}

.profile-main .order-heading {
  text-align: center;
}
/* @media only screen and (min-width: 1500px) {
  .profile-main .order-form-container {
    width: 1360px; 
  }
} */
.profile-main .order-heading h2 {
  color: gray;
  font-weight: 400;
  margin-top: 10px;
  font-size: 20px;
}
.profile-main .order-active {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 70px;
}
.profile-main .contact-detail-btn-sub,
.profile-main .review-complete-btn-sub {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}
.profile-main .contact-detail-btn-sub h3,
.profile-main .review-complete-btn-sub h3 {
  font-weight: 400;
  font-size: 15px;
}

.profile-main .contact-detail-btn {
  height: 15px;
  width: 200px;
  border-radius: 20px;
  background-color: #800020;
}
.profile-main .review-complete-btn {
  height: 15px;
  width: 200px;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.profile-main .order-form-container {
  max-width: 700px;
  margin: 0 auto;
  padding: 50px 130px;
  margin-top: 30px;
  border: 1px solid #ccc;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.profile-main .form-row {
  display: flex;
  justify-content: space-between;
}

.profile-main .form-group {
  margin-bottom: 12px;
  flex: 1;
}

.profile-main .form-group label {
  display: block;
  margin-bottom: 5px;
}

.profile-main .form-group input {
  width: calc(100% - 10px);
  padding: 8px;
  box-sizing: border-box;
  border: 2px solid #800020;
}
.profile-main .form-group input:focus {
  outline: none;
  border: 2px solid #525ced;
}

/* .profile-main .form-group:nth-child(odd) {
    margin-right: 10px;
  } */

.profile-main .order-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.profile-main .order-btn button {
  display: block;
  width: 180px;
  font-weight: 500;
  font-size: 1rem;
  padding: 10px;
  background-color: #800020;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.profile-main .order-btn button:hover {
  background-color: #440414;
}
.profile-main .add-more {
  width: fit-content;
  margin: 2px 5px 10px 0;
  padding: 2px;
  border-radius: 50%;
  border-style: none;
  background-color: #800020;
  display: flex;
  justify-content: center;
  color: #ffffff;
  align-items: center;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
}

.swal2-popup {
  margin-top: 30px;
  width: 400px;
  height: 365px;
}
.swal2-confirm {
  background-color: #800020 !important;
}
.profile-main .country-code {
  width: calc(100% - 10px);
  padding: 8px;
  box-sizing: border-box;
  border: 2px solid #800020;
}
.profile-main input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media screen and (max-width: 700px) {
  .profile-main .order-form-container {
    padding: 50px 40px;
  }
}
@media screen and (max-width: 450px) {
  .profile-main .order-form-container {
    padding: 50px 20px;
  }
  .profile-main .contact-detail-btn {
    height: 15px;
    width: 150px;
    border-radius: 20px;
    background-color: #800020;
  }
  .profile-main .review-complete-btn {
    height: 15px;
    width: 150px;
    border-radius: 20px;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .profile-main .form-group label {
    display: block;
    width: fit-content;
    font-size: 14px;
    margin-bottom: 4px;
  }
  .profile-main .order-heading h1,
  h2 {
    text-align: center;
    padding: 0 10px;
  }
}
@media screen and (max-width: 355px) {
  .profile-main .form-group label {
    display: block;
    width: fit-content;
    font-size: 10px;
    margin-bottom: 4px;
  }
}

.price-detail-table {
  padding: 10px;
  max-width: 600px;
  margin: auto;
}

.price-detail-table h1 {
  text-align: center;
  margin-bottom: 10px;
}

.price-detail-table table {
  width: 100%;
  border-collapse: collapse;
}

.price-detail-table th,
.price-detail-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.price-detail-table tr.total {
  font-weight: bold;
}

.price-detail-table tr.total td {
  background-color: #e0e0e0;
}

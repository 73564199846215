@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  width: 100%;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  scroll-behavior: smooth;
}

body a {
  text-decoration: none;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

:root {
  
}

/* --------------- Webkit Scrollbar --------------- */
::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-track {
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  background-color: #1F1F1F;
  border: 6px solid transparent;
}

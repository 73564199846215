.place-order-bottom-container{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
:root {
  --active-step-color: #FFFFFF;
  --active-step-bg-color: #800020;
}

form {
  display: flex;
  flex-direction: column;
}

.form-section {
  margin-bottom: 20px;
}
 


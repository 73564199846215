.place-order-top-container {
  width: 100%;
  /* height: 200px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ddd;
  padding-top: 50px;
}

.place-order-bottom-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  width: 60%;
}

.progress-bar-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
}
.progress-bar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 65%;
}

.steps {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  width: 50%;
}

.step {
  display: flex;
  width: 84px;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
  position: relative;
  z-index: 1;
}

.circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: #800000;
  z-index: 2;
}
.step:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 25%;
  left: 50%;
  width: 100%;
  height: 2px;
  background-color: #000000;
  z-index: 1;
}

.step:not(:first-child)::before {
  content: "";
  position: absolute;
  top: 25%;
  left: -50%;
  width: 100%;
  height: 2px;
  background-color: #000000;
  z-index: 1;
}

.parcel-step-form {
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
}

.form-section {
  margin-bottom: 20px;
}

.progressbar-label {
  margin-top: 10px;
  font-size: 20px;
  color: #333;
  font-family: "Inter";
  font-weight: 500;
  line-height: 24.2px;
}
.parcel-step-input {
  width: 100%;
  padding: 10px;
  margin: 5px 0 10px;
  border: 2px solid #800000;
}

.parcel-step-heading {
  margin-bottom: 70px;
  margin-top: 40px;
  text-align: center;
  font-weight: 700;
  font-size: 43px;
}

.parcel-step-label {
  margin: 5px 0;
  font-family: "Inter";
}

.step.active .circle {
  color: var(--active-step-color);
  background-color: var(--active-step-bg-color);
}

.progress-bar-heading {
  text-align: center;
  font-weight: 700;
  font-size: 43px;
  line-height: 50.48px;
}

.navigate-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.navigation-container {
  width: 60%;
  display: flex;
  justify-content: space-around;
}

.parcel-step-type-button,
.parcel-step-type-submit {
  background-color: #800000;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  align-self: center;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 24.2px;
  width: 180px;
  padding: 10px 0;
  transition: all 0.2s ease-in;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}

.review-step-type-button:hover,
.review-step-type-submit:hover,
.parcel-step-type-button:hover,
.parcel-step-type-submit:hover {
  color: #660000;
  background-color: #ddd;
}

@media (max-width: 1024px) {
  .steps {
    width: 80%;
  }
}
@media (min-width: 768px) {
  .progress-bar-container {
    margin-top: 50px;
  }
}

@media (max-width: 768px) {
  .steps {
    width: 100%;
    justify-content: center;
  }
  .circle {
    width: 40px;
    height: 40px;
  }
  .step {
    width: 39px;
  }

  .progressbar-label {
    font-size: 14px;
  }
  .progress-bar-heading,
  .parcel-step-heading {
    font-size: 25px;
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .container {
    width: 89%;
  }

  .place-order-top-container {
    padding-top: 0px;
  }
}

@media (max-width: 600px) {
  .progress-bar-heading {
    font-size: 1.5em;
  }

  .parcel-step-heading {
    font-size: 1.5em;
  }
  .parcel-step-input {
    padding: 8px;
  }
  .review-step-type-button,
  .review-step-type-submit,
  .parcel-step-type-button,
  .parcel-step-type-submit {
    padding: 8px 16px;
  }
}

@media (min-width: 1658px) {
  .step:not(:first-child)::before {
    top: 35px;
  }

  .step:not(:last-child)::after {
    top: 35px;
    left: 75px;
  }

  .circle {
    width: 80px;
    height: 80px;
  }
}

@media (min-width: 2000px) {
  .step:not(:last-child)::after {
    top: 41px;
    left: 82px;
  }
}

@media (min-width: 2000px) {
  .step:not(:first-child)::before {
    top: 41px;
    left: -99%;
  }
}

@media (min-width: 2521px) {
  .step:not(:last-child)::after {
    width: 300%;
  }
}

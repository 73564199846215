.parcelbg{
  background-color: #d4d4d4;
  padding: 15px;
}

.parcel-details-main{
  max-width: 800px;
  margin: auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.parcel-details-main .parcel-detail {
  margin-bottom: 40px;
  border-radius: 8px;
}
.parcel-details-main h2{
  text-align: start;
  margin-top: 20px;
  font-size: 20px;
  font-weight: 500;
}

.parcel-details-main h1, .parcel-details-main h3 {
  text-align: center;
}

.parcel-details-main hr {
  margin: 20px 0;
}

.parcel-details-main .parcel-info {
  display: flex;
  align-items: center;
}

.parcel-details-main .parcel-info img {
  max-width: 170px;
  height: 170px;
  object-fit: cover;
  margin-right: 20px;
  border-radius: 5px;
}

.parcel-details-main .parcel-content{
  align-self: start;
}

.parcel-details-main .parcel-content p {
  margin: 5px 0;
}
.parcel-details-main .parcel-content .small-detail {
  display: flex;
  gap: 30px;
}

.parcel-details-main .sender-receiver-details {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.parcel-details-main .sender-details, .parcel-details-main .receiver-details {
  padding: 10px;
  border: 1px solid #ddd;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.parcel-details-main .sender-details h3, .parcel-details-main .receiver-details h3 {
  text-align: start;
  margin: 3px 0;

}

@media screen and (max-width:600px){
  .parcel-details-main .parcel-info {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .parcel-details-main .parcel-content .small-detail {
    display: flex;
    gap: 0px;
    flex-direction: column;
}
}

@media screen and (max-width:400px){
  .parcel-details-main {
    padding: 10px; 
}
.parcelbg{
  background-color: #d4d4d4;
  padding: 10px;
}
/*  */
}
  





.homeContainer .testimonialBox {
  width: 100%;
  background-color: #d4d4d4;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #1f1f1f;
  padding: 40px 0;
}

.homeContainer .testimonialBox .mainSlider {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 20px 0;
}

.homeContainer .testimonialBox .mainSlider .slider {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  gap: 40px;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  height: 300px;
  scroll-behavior: smooth;
  align-items: center;
  padding: 0 30px;
  box-sizing: border-box;
}

.homeContainer .testimonialBox .mainSlider .slider::-webkit-scrollbar {
  display: none;
}

.homeContainer .testimonialBox .mainSlider .slider .box {
  width: 280px;
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin: 10px;
  flex-shrink: 0;
  cursor: pointer;
}

.homeContainer .testimonialBox .mainSlider .slider .box img {
  width: 80px;
  height: 80px;
  z-index: 1;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-radius: 50%;
}

.homeContainer .testimonialBox .mainSlider .slider .box:hover img {
  transform: scaleX(-1);
  transition: transform 0.3s ease-in-out;
}

.homeContainer .testimonialBox .mainSlider .slider .box p {
  width: 100%;
  height: 80px;
  padding: 20px;
  padding-top: 40px;
  background-color: #fff;
  position: relative;
  top: -20px;
  text-align: center;
  border-radius: 10px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.homeContainer .testimonialBox .mainSlider .arrow {
  background-color: #800020;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.homeContainer .testimonialBox .mainSlider .left-arrow {
  left: -25px;
}

.homeContainer .testimonialBox .mainSlider .right-arrow {
  right: -25px;
}

@media only screen and (max-width: 433px) {
  .homeContainer .testimonialBox .mainSlider .slider .box {
    width: 100%;
  }

  .homeContainer .testimonialBox .mainSlider .slider .box p {
    height: auto;
  }
}

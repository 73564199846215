.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1px;
  flex-direction: column;
}

.footer .upFoot,
.footer .middleFoot,
.footer .downFoot {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1f1f1f;
}

.footer .middleFoot {
  background-color: #000000;
}

.footer .sizeBox {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media only screen and (min-width: 1361px) {
  .footer .sizeBox {
    width: 1360px;
  }
}

.footer .upFoot {
  min-height: 180px;
}

.footer .upFoot .sizeBox .left,
.footer .upFoot .sizeBox .right {
  width: 40%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  color: #d4d4d4;
}

.footer .upFoot .sizeBox .right .subscribe {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer .upFoot .sizeBox .right .subscribe input {
  border-style: none;
  margin: 10px 20px 0 0;
  padding: 10px 20px;
  width: 70%;
  font-size: 1rem;
  border-radius: 5px;
  color: #000000;
}

.footer .upFoot .sizeBox .right .subscribe button {
  border-style: none;
  width: 30%;
  margin: 10px 20px 0 0;
  padding: 11px 20px;
  font-size: 1rem;
  border-radius: 5px;
  color: #fff;
  background-color: #800020;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.footer .upFoot .sizeBox .right .subscribe button:hover {
  background-color: #a0032a;
}

.footer .upFoot .sizeBox .right .subscribe input:focus {
  outline: none;
}

.footer .middleFoot {
  min-height: 250px;
  color: #d4d4d4;
}

.footer .middleFoot .sizeBox .box1 {
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.footer .middleFoot .sizeBox .box {
  width: 15%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.footer .middleFoot .sizeBox .box a {
  text-decoration: none;
  color: #d4d4d4;
  margin: 5px 0;
  font-size: 1rem;
}

.footer .middleFoot .sizeBox .box a:hover {
  color: #fff;
}

@media only screen and (max-width: 800px) {
  .footer .sizeBox {
    flex-direction: column;
  }

  .footer .upFoot,
  .footer .middleFoot,
  .footer .downFoot {
    padding: 30px 0;
  }

  .footer .upFoot .sizeBox .left,
  .footer .upFoot .sizeBox .right {
    width: 90%;
  }

  .footer .upFoot .sizeBox .right .subscribe {
    margin: 20px 0;
  }

  .footer .middleFoot .sizeBox .box1 {
    width: 90%;
    margin: 30px 0;
  }

  .footer .middleFoot .sizeBox .box {
    width: 90%;
    margin: 20px 0;
  }
}

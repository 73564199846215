.trackPlaceCont {
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px;
  box-shadow: rgba(0, 0, 0, 0.288) 0px 3px 12px;
}

@media only screen and (max-width: 580px) {
  .trackPlaceCont {
    width: 100%;
  }
}

.trackPlaceCont .up {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.trackPlaceCont .up .box {
  width: 44.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-transform: uppercase;
  font-weight: 600;
  padding: 20px 10px;
  cursor: pointer;
}

.trackPlaceCont .track,
.trackPlaceCont .track form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  font-weight: 550;
  font-size: 1rem;
}

.trackPlaceCont .track form p,
.trackPlaceCont .track form input {
  width: 80%;
  color: rgb(17, 17, 17);
}

.trackPlaceCont .track form p {
  margin: 20px 0px 5px 0px;
}

.trackPlaceCont .track form input {
  width: 75%;
  border-style: none;
  margin: 5px 0;
  padding: 10px;
  font-size: 1rem;
  border: 2px solid #800020;
  border-radius: 5px;
}

.trackPlaceCont .track form input:focus {
  outline: none;
}

.trackPlaceCont .track form button {
  width: 100%;
  margin-top: 30px;
  padding: 15px 0;
  font-weight: 550;
  font-size: 1rem;
  border-style: none;
  background-color: #800020;
  color: #fff;
  cursor: pointer;
}

.trackPlaceCont .track form button:hover {
  background-color: #a0032a;
}

.trackPlaceCont .ship,
.trackPlaceCont .ship form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  font-weight: 550;
  font-size: 1rem;
}

.trackPlaceCont .ship form {
  width: 80%;
}

.trackPlaceCont .ship form h2 {
  align-self: flex-start;
  margin: 40px 0 0 0px;
}

.trackPlaceCont .ship form h2 strong {
  background: linear-gradient(to right, #800020, #033186);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.trackPlaceCont .ship form .innerBox {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}

.trackPlaceCont .ship form .innerBox .left {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.trackPlaceCont .ship form .innerBox .left .r {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #800020;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trackPlaceCont .ship form .innerBox .left hr {
  width: 0;
  height: 50px;
  border: 1px solid #800020;
}

.trackPlaceCont .ship form .innerBox .right {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.trackPlaceCont .ship form .innerBox .right input {
  width: 80%;
  border-style: none;
  margin: 10px 0;
  padding: 10px;
  font-size: 1rem;
  border: 2px solid #800020;
  border-radius: 5px;
}

.trackPlaceCont .ship form .innerBox .right input:focus {
  outline: none;
}

.trackPlaceCont .ship form button {
  margin-bottom: 20px;
  padding: 10px 20px;
  font-weight: 550;
  border-radius: 5px;
  font-size: 1rem;
  border-style: none;
  background-color: #800020;
  color: #fff;
  cursor: pointer;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.trackPlaceCont .ship form button:hover {
  background-color: #a0032a;
}

#movingImage {
  position: absolute;
  transition: all 1s ease;
  width: 35px;
}

#movingImage.move-to-first {
  transform: translateY(0px);
}

#movingImage.move-to-end {
  transform: translateY(61px);
}

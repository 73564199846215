.navbar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1px;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  position: sticky;
  top: 0;
  z-index: 998;
}

.navbar .upNav,
.navbar .downNav {
  width: 100%;
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1f1f1f;
}

.navbar .downNav {
  background-color: #fff;
}

.navbar .upNav .sizeBox,
.navbar .downNav .sizeBox {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (min-width: 1361px) {
  .navbar .upNav .sizeBox,
  .navbar .downNav .sizeBox {
    width: 1360px;
  }
}

.navbar .upNav .sizeBox .leftNav,
.navbar .upNav .sizeBox .rightNav {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.navbar .upNav .sizeBox .rightNav {
  justify-content: flex-end;
}

.navbar .upNav .sizeBox .rightNav span {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
}

.navbar .upNav .sizeBox .leftNav .searchBox {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 7px 20px;
  border-radius: 10px;
}

.navbar .upNav .sizeBox .leftNav input {
  border-style: none;
  margin-right: 5px;
  font-size: 1rem;
  color: #1f1f1f;
}

.navbar .upNav .sizeBox .leftNav input::placeholder {
  font-size: 1rem;
  color: #1f1f1f;
  font-weight: 500;
}

.navbar .upNav .sizeBox .leftNav input:focus {
  outline: none;
}

.navbar .downNav .sizeBox .leftNav {
  width: 15%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.navbar .downNav .sizeBox .drawer {
  width: 85%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar .downNav .sizeBox .menu {
  width: 25%;
  display: none;
  justify-content: flex-end;
  align-items: center;
}

.navbar .downNav .sizeBox .drawer .rightNav {
  width: 25%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.navbar .downNav .sizeBox .drawer .middleNav {
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar .downNav .sizeBox .rightNav a {
  padding: 0.5em 0.5em;
  text-decoration: none;
  color: #1f1f1f;
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar .downNav .sizeBox .middleNav * {
  box-sizing: border-box;
  transition: all 0.35s ease;
}

.navbar .downNav .sizeBox .middleNav a {
  padding: 0.5em 0.8em;
  position: relative;
  text-decoration: none;
  color: #1f1f1f;
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar .downNav .sizeBox .middleNav a::before,
.navbar .downNav .sizeBox .middleNav a::after {
  content: "";
  height: 14px;
  width: 14px;
  position: absolute;
  transition: all 0.35s ease;
  opacity: 0;
}

.navbar .downNav .sizeBox .middleNav a::before {
  content: "";
  right: 0;
  top: 0;
  border-top: 3px solid #800020;
  border-right: 3px solid #a0032a;
  transform: translate(-100%, 50%);
}

.navbar .downNav .sizeBox .middleNav a:after {
  content: "";
  left: 0;
  bottom: 0;
  border-bottom: 3px solid #a0032a;
  border-left: 3px solid #800020;
  transform: translate(100%, -50%);
}

.navbar .downNav .sizeBox .middleNav a:hover:before,
.navbar .downNav .sizeBox .middleNav a:hover:after {
  transform: translate(0, 0);
  opacity: 1;
}

.navbar .downNav .sizeBox .middleNav a:hover {
  color: #800020;
}

.navbar .downNav .sizeBox .middleNav .active,
.navbar .downNav .sizeBox .rightNav .active {
  color: #800020;
}
.navbar .downNav .sizeBox .leftNav img {
  margin: 10px 0;
  width: 110px;
}

@media only screen and (max-width: 580px) {
  .navbar .upNav .sizeBox .leftNav {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .navbar .upNav .sizeBox .rightNav {
    display: none;
  }
}

@media only screen and (max-width: 890px) {
  .navbar .upNav .sizeBox,
  .navbar .downNav .sizeBox {
    width: 100%;
  }
  .navbar .upNav .sizeBox {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 835px) {
  .navbar .downNav .sizeBox .leftNav img {
    margin: 10px;
  }

  .navbar .downNav .sizeBox .menu {
    display: flex;
    margin: 15px;
  }

  .navbar .downNav .sizeBox .drawer {
    opacity: 1;
    transform: translateX(196px);
    transition: opacity 0.2s ease-in, transform 0.2s ease-in;
    position: fixed;
    top: 0;
    right: 0;
    width: 200px;
    flex-direction: column;
    justify-content: start;
    height: 100vh;
    background-color: #fff;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
      rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    visibility: hidden; /* Initially hidden */
  }

  .navbar .downNav .sizeBox .drawer.open {
    opacity: 1;
    transform: translateX(0);
    visibility: visible; /* Visible when open */
  }

  .navbar .downNav .sizeBox .drawer .rightNav,
  .navbar .downNav .sizeBox .drawer .middleNav {
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }

  .navbar .downNav .sizeBox .drawer a {
    margin: 10px 15px;
  }
}

.navbar .downNav .sizeBox .rightNav .dropBox {
  position: absolute;
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  top: 55px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 10px;
}

.navbar .downNav .sizeBox .rightNav .dropBox a,
.navbar .downNav .sizeBox .rightNav .dropBox span {
  width: 95%;
  margin: 5px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  font-size: 1rem;
  padding: 5px;
  border-radius: 5px;
}

.navbar .downNav .sizeBox .rightNav .dropBox a:hover,
.navbar .downNav .sizeBox .rightNav .dropBox span:hover {
  background-color: #800020;
  color: #fff;
}

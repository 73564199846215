.parent{
    background-color: rgba(217, 217, 217, 1);
    margin-top: 31px;
}
.parent .upper-sec{
    height: 240px;
    /* min-width: 1330px; */
}
.parent .upper-sec h1{
    height: 100%;
    font-size: 37px;
    letter-spacing: 2px;
    text-align: right;
    color: rgba(0, 0, 0, 1);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 39px;
}
.parent .upper-color{
    background-color: rgba(128, 0, 32, 1);
    height: 330px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.parent .upper-color .right img{
    position: relative;
    bottom: 74px;
}
.parent .upper-color .left p{
    width: 430px;
    color: white;
    line-height: 1.5;
    letter-spacing: 2px;
}

.parent .form-sec{
    background-color: rgba(31, 31, 31, 1);
    border-radius: 8.5px;
    height: 520px;
    margin: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 190px;
}
.parent .form-sec .form-left .icons{
    display: flex;
    gap: 8px;
}
.parent .form-sec .form-left .icons img{
    border: 0.4px solid white;
    padding: 3px;
}
.parent .form-sec .res-icons{
    display: none;
}
.parent .form-sec .form-left h1{
    color: white;
    font-size: 39px;
    letter-spacing: 2.5px;
    width: 170px;
    font-weight: 200;
}
.parent .form-sec .form-right form{
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 18px;
}
.parent .form-sec .form-right form input,textarea{
    background-color: rgba(217, 217, 217, 1);
    padding: 12px;
    width: 400px;
    border-radius: 5px;
}
.parent .form-sec .form-right form textarea{
    height: 70px;
    resize: none;
}
.parent .form-sec .form-right form button{
    background-color: rgba(128, 0, 32, 1);
    padding: 11px;
    border-radius: 5px;
    color: white;
    border: none;
    font-weight: 550;
    margin-left: 290px;
    width: 140px;
}

.parent .boxes-sec{
    max-width: 100vw;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 60px;
}
.parent .boxes-sec .box{
    height: 156px;
    width: 280px;
    background-color: white;
    box-shadow: 0 1px 1px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.12), 0 4px 4px rgba(0,0,0,0.12), 0 8px 8px rgba(0,0,0,0.12), 0 16px 16px rgba(0,0,0,0.12);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
}
.parent .boxes-sec .box .img .ellipse{
    height: 50px;
    width: 50px;
    border-radius: 50px;
    background-color: rgba(128, 0, 32, 0.34);
    position: relative;
    top: 7px;
}
.parent .boxes-sec .box .img img{
    position: relative;
    bottom: 30px;
    height: 50px;
}
.parent .boxes-sec .box h2{
    font-size: 18px;
    margin-bottom: 17px;
    letter-spacing: 1.5px;
}
.parent .boxes-sec .box p{
    font-size: 11px;
    letter-spacing: 1.3px;
}

.parent .large-box{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 90px;
    margin: 10px 120px;
}
.parent .large-box .text-sec{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
}
.parent .large-box .text-sec h1{
    letter-spacing: 2.7px;
    width: 300px;
}
.parent .large-box .text-sec p{
    font-size: 12px;
    width: 380px;
}
.parent .large-box .text-sec button{
    background-color: black;
    color: white;
    border: none;
    padding: 12px;
    border-radius: 5px;
}
.parent .large-box .box-sec{
    height: 450px;
    width: 450px;
    border-radius: 6px;
    background-color: rgba(128, 0, 32, 1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
}
.parent .large-box .box-sec h2{
    padding: 10px 45px;
    background-color: white;
    border-radius: 7px;
    font-weight: 400;
}
.parent .large-box .box-sec .text h3{
    color: white;
    font-size: 20px;
    font-weight: 450;
}
.parent .large-box .box-sec .text p{
    text-align: center;
    color: white;
    font-weight: 250;
    font-size: 14px;
}

.faq-heading{
    text-align: center;
    margin: 66px 0px;
}
.parent .faq{
    background-color: white;
    display: flex;
    padding: 90px 100px;
    gap: 100px;
    justify-content: center;
    align-items: center;
}
.parent .faq .faq-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 28px;
}
.parent .faq .faq-text .faq-textarea{
    display: flex;
    gap: 16px;
    align-items: center;
}
.parent .faq .faq-text .faq-textarea img{
    height: 31px;
}
.parent .faq .faq-text .faq-textarea p{
    font-size: 22px;
    letter-spacing: 1.8px;
    font-weight: 550;
}
.parent .faq .faq-form{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 55px;
}
.parent .faq .faq-form h1{
    letter-spacing: 1.5px;
    font-weight: 500;
}
.parent .faq .faq-form input{
    border: none;
    width: 390px;
}
.parent .faq .faq-form input::placeholder{
    font-weight: 900;
    color: black;
    font-style: bold;
}
.parent .faq .faq-form hr{
    height: 2px;
    background-color: black;
    margin: 4px 0;
}
.parent .faq .faq-form button{
    width: 100px;
    background-color: black;
    color: white;
    padding: 10px;
    border-radius: 5px;
    border: none;
    align-self: flex-end;
}
@media only screen and (max-width: 1000px){
    .parent{
        margin-top: 0px;
    }
    .parent .upper-color .left p {
        position: relative;
        bottom: 220px;
        left: 243px;
        /* padding-top: 20px; */
        width: 380px;
        line-height: 1.6;
        /* width: 300px; */
        text-align: center;
    }
    .parent .form-sec{
        flex-direction: column;
        gap: 10px;
    }
    .parent .form-sec .res-icons{
        display: flex;
        gap: 8px;
    }
    .parent .form-sec .res-icons img{
        border: 0.4px solid white;
        padding: 3px;
    }
    .parent .form-sec .form-left .icons{
        display: none;
    }
    .parent .form-sec .form-left h1{
        font-weight: 650;
        width: 350px;
        padding: 40px 0;
    }
    .parent .form-sec .form-right form button{
        margin-left: 0px;
        padding: 15px;
    }
    .parent .boxes-sec{
        flex-direction: column;
        gap: 15px;
        margin: 120px 0;
    }
    .parent .boxes-sec .box{
        width: 330px;
        align-items: flex-start;
        padding: 0 34px;
    }
    .parent .large-box{
        flex-direction:column;
        gap: 27px;
    }
    .parent .large-box .text-sec h1{
        text-align: center;
        width: 350px;
    }
    .parent .large-box .text-sec p{
        text-align: center;
        font-size: 15px;
    }
    .parent .large-box .text-sec{
        align-items: center;
    }
    .parent .faq{
        flex-direction: column;
        gap: 75px;
    }
    .parent .faq .faq-form{
        align-items: center;
    }
    .parent .faq .faq-form button{
        padding: 18px;
    }
    .parent .upper-sec{
        height: 0px;
    }
    .parent .upper-sec h1{
        justify-content: center;
        color: white;
        padding-top: 180px;
        letter-spacing: 4px;
        text-align: center;
        font-size: 45px;
    }
    .parent .upper-color{
        height: 580px;
        display: block;
    }
    .parent .upper-color .right img {
        opacity: 0.5;
        left: 134px;
        bottom: -101px;
    }
}
@media only screen and (max-width: 780px){
    .parent .upper-color .right img{
        height: 470px;
        bottom: -100px;
        left: 80px;
    }
    .parent .upper-color .left p {
        bottom: 202px;
        left: 178px;
    }
    .parent .form-sec .form-right form input,textarea{
        width: 280px;
    }
}
@media only screen and (max-width: 580px){
    .parent .upper-color .right img{
        height: 450px;
        width: 440px;
        bottom: -120px;
    }
    .parent .upper-color .left p{
        bottom: 178px;
        left: 80px;
    }
}
@media only screen and (max-width: 490px){
    .parent .upper-color{
        height: 481px;
    }
    .parent .upper-color .right img{
        height: 450px;
        width: 355px;
        bottom: -21px;
        left: 9px;
    }
    .parent .upper-color .left p{
        left: 62px;
        width: 258px;
        bottom: 221px;
    }
    .parent .form-sec{
        margin: 10px;
    }
    .parent .form-sec .form-left h1{
        text-align: center;
    }
    .parent .form-sec .form-right form{
        width: 359px;
    }
    .parent .form-sec .form-right form input,textarea{
        width: 247px;
    }
    .parent .large-box .text-sec p{
        width: 220px;
    }
    .parent .large-box .box-sec{
        width: 300px;
    }
    .parent .faq .faq-text .faq-textarea p{
        width: 210px;
    }
    .parent .faq .faq-form input{
        width: 250px;
    }
    .parent .faq .faq-form button{
        align-self: center;
    }

}
@media only screen and (max-width: 345px){
    .parent .upper-color .right img{
        height: 450px;
        width: 280px;
        bottom: -26px;
    }
}
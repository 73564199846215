.registerPage .rightReg .innerRegister .signupCont {
  width: 80%;
  height: 100%;
  padding: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.registerPage .rightReg .innerRegister .signupCont h2 {
  align-self: flex-start;
  color: #1f1f1f;
}

.registerPage .rightReg .innerRegister .signupCont p {
  font-size: 1rem;
  align-self: flex-start;
  color: #9c9b9b;
  margin: 10px 0;
}

.registerPage .rightReg .innerRegister .signupCont form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px 0;
}

.registerPage .rightReg .innerRegister .signupCont form input {
  outline: none;
  border-style: none;
  font-size: 1rem;
  width: 100%;
  background: transparent;
  padding: 10px 5px;
}

.registerPage .rightReg .innerRegister .signupCont form input::placeholder {
  color: #929090;
  font-weight: 550;
}

.registerPage .rightReg .innerRegister .signupCont form .button {
  width: 100%;
  margin: 25px 0 0 0;
  padding: 10px 0;
  border-radius: 5px;
  border-style: none;
  font-size: 1.1rem;
  cursor: pointer;
  background-color: #800020;
  color: #fff;
  font-weight: 500;
  transition: 0.1s ease-in;
}

.registerPage .rightReg .innerRegister .signupCont form .button:hover {
  background-color: #ad0731;
}

@media only screen and (max-width: 318px) {
  .registerPage .rightReg .innerRegister .signupCont {
    width: 90%;
  }
}

.registerPage .rightReg .innerRegister .signupCont form .otpBox input {
  background-color: #fff;
  width: 20px;
  height: 20px;
  padding: 10px;
  text-align: center;
  margin: 15px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
  font-size: 1.1rem;
  font-weight: 500;
  color: #1f1f1f;
  cursor: pointer;
}

.registerPage .rightReg .innerRegister .signupCont form .otpBox input:focus {
  background-color: #ad0731;
  color: #fff;
}

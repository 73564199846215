.parcel-list {
  width: 85%;
  margin: 0 auto;
}
.parcel-list .view-all-box {
  display: flex;
  justify-content: end;
  align-items: end;
}

.parcel-list .view-all-button {
  display: block;
  margin: 10px 0;
  padding: 10px 20px;
  background-color: #800020;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.parcel-list .parcel-block {
  display: flex;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  margin-bottom: 20px;
}

.parcel-list .parcel-image {
  width: 80px;
  height: 80px;
  margin-right: 10px;
  object-fit: cover;
}

.parcel-list .parcel-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0 10px;
}

.parcel-list .parcel-name {
  font-weight: 600;
  font-size: 20px;
}

.parcel-list .parcel-description p {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.parcel-list .parcel-name, .parcel-list .parcel-description, .parcel-list .parcel-price {
  margin: 2px 0;
  line-clamp: 1;
}

.parcel-list .view-button {
  padding: 7px 12px;
  background-color: #800020;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  width: 14%;
  text-align: center;
  margin-top: 10px;
}

/* Media query for small screens */
@media (max-width: 550px) {
  .parcel-list .parcel-block {
      flex-direction: row;
      align-items: flex-start;
      flex-wrap: wrap;
  }

  .parcel-list .parcel-image {
      margin-bottom: 10px;
  }

  .parcel-list .parcel-info {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin: 0 10px 0 0;
  }

  .parcel-list .view-button {
      order: 1;
      align-self: stretch;
      text-align: center;
      margin-top: 10px;
      margin-left: 0;
      width: 55%;
  }
}
@media (max-width: 400px){
  .profilepage-main .shipping-history {
    background-color: #fff;
    margin-top: 20px;
    padding: 5px;
  }
  .parcel-list .view-button {
    order: 1;
    align-self: stretch;
    text-align: center;
    margin-top: 4px;
    margin-left: 0;
    width: 40%;
}

}
.faq-container .track-main {
  text-align: center;
  padding: 60px;
}

.faq-container .track-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
  margin-top: 90px;
}

.faq-container .track-card {
  color: rgba(255, 255, 255, 1);
  height: 330px;
  width: 220px;
  box-sizing: border-box;
  gap: 16px;
  padding: 30px;
  border: 1px solid #ccc;
  border-radius: 28px;
  text-align: flex-start;
  text-align: left;
  background: rgba(128, 0, 32, 1);
  display: flex;
  flex-direction: column;
  transition: transform 0.5s ease, box-shadow 0.1s ease;
}

.faq-container .track-card:hover {
  transform: translateY(-15px);
  box-shadow: 0 20px 26px rgba(0, 0, 0, 0.2);
}

.faq-container .track-card img {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  border: none;
}

.faq-container .track-card h3 {
  font-size: 22px;
  height: 4.5rem;
}

.faq-container .track-card p {
  font-size: 14px;
  font-weight: 500;
}

@media (max-width: 746px) {
  .faq-container h1 {
    font-size: 1.5rem;
  }
}

@media (max-width: 450px) {
  .faq-container .track-card h3 {
    height: auto;
    font-size: 1.1rem;
  }
}

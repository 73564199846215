.review-rating-main {
  /* background-color: #e0e0e0; */
  width: 100%;
  margin-top: 20px;
}
.review-rating-main h1 {
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-weight: 500;
}

.review-rating-main .review-rating {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 50%;
  padding: 10px;
  margin: 20px auto;
  /* border-radius: 8px; */
}

.review-rating-main .review-rating .rating-item {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  cursor: pointer;
}

.review-rating-main .icon {
  font-size: 2rem;
}

.review-rating-main .bad,
.review-rating-main .ok,
.review-rating-main .average,
.review-rating-main .good,
.review-rating-main .best {
  color: #9e9e9e;
}

.review-rating-main .rating-item:hover .bad,
.review-rating-main .rating-item.selected .bad {
  color: red;
  scale: 1.15;
}

.review-rating-main .rating-item:hover .ok,
.review-rating-main .rating-item.selected .ok {
  color: orange;
  scale: 1.15;
}

.review-rating-main .rating-item:hover .average,
.review-rating-main .rating-item.selected .average {
  color: yellow;
  scale: 1.15;
}

.review-rating-main .rating-item:hover .good,
.review-rating-main .rating-item.selected .good {
  color: lightgreen;
  scale: 1.15;
}

.review-rating-main .rating-item:hover .best,
.review-rating-main .rating-item.selected .best {
  color: green;
  scale: 1.15;
}

@media screen and (max-width: 600px) {
  .review-rating-main .review-rating {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 95%;
    padding: 5px;
    margin: 20px auto;
  }
  
}
  

 
.placeorder-completion{
    padding: 5rem 0;
    background-color: #d4d4d4;
}
@media only screen and (min-width: 1500px) {
    .placeorder-completion {
      /* width: 1360px; */
      height: 100vh;
    }
  }
.placeorder-completion .heading{
    text-align: center;
    padding: 0 28rem;
}

.placeorder-completion .order-active{
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 70px;
}
.placeorder-completion .contact-detail-btn-sub , .placeorder-completion .review-complete-btn-sub{
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;  
}
.placeorder-completion .contact-detail-btn-sub h3 , .placeorder-completion .review-complete-btn-sub h3{
    font-weight: 400;
    font-size: 15px;  
}

.placeorder-completion .contact-detail-btn{
    height: 15px;
    width: 200px;
    border-radius: 20px;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
 
}
.placeorder-completion .review-complete-btn{
   height: 15px;
   width: 200px;
   border-radius: 20px;
   background-color: #800020;
}

.placeorder-completion .contact-details{
    max-width: 700px;
    margin: 0 auto;
    padding: 30px 130px;
    margin-top: 30px;
    border: 1px solid #ccc;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.placeorder-completion .contact-details h3{
    font-size: 25px;
    text-align: center;
}
.placeorder-completion .completion-detail-main{
    display: flex;
    margin-top: 20px;
    width: calc(100% - 20px);
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}
.placeorder-completion .address-detail{
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}
.placeorder-completion .completion-detail-main h4{
    font-weight: 400;
    display: flex;
}
.placeorder-completion .completion-detail-div{
    width: 500px;
    height: auto;
    color: #333;
    font-weight: bold;
    
}

.placeorder-completion .order-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    gap: 20px;
  }
  
  .placeorder-completion button {
    display: block;
    width: 150px;
    padding: 10px;
    background-color: #800020;
    color: white;
    border: none;
    font-weight: 500;
    font-size: 1.1rem;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .placeorder-completion button:hover {
    background-color: #440414;
  }
  @media screen and (max-width:1200px){
    .placeorder-completion .heading{
        text-align: center;
        padding: 0 10rem;
    }
  }
  @media screen and (max-width:950px){
    .placeorder-completion .completion-detail-div{
        width: 350px;
    }
  }
  @media screen and (max-width:800px){
    .placeorder-completion .completion-detail-div{
        width: 260px;
    }
  }
  @media screen and (max-width:700px){
    .placeorder-completion .heading{
        text-align: center;
        padding: 0 5rem;
    }
    .placeorder-completion .completion-detail-div{
        width: 300px;
        height: auto;
        color: #333;
        font-weight: bold;
        
    }
    .placeorder-completion .contact-details h3{
        font-size: 25px;
        text-align: center;
        margin: 12px 0 ;
    }
    .placeorder-completion .completion-detail-div{
        width: 150px;
    }
  }
  @media screen and (max-width:600px){
    .placeorder-completion .contact-details{
        max-width: 700px;
        margin: 0 auto;
        padding: 30px 60px;
        margin-top: 30px;
        
    }
  }
  @media screen and (max-width:450px){
    .placeorder-completion .order-form-container {
      padding: 50px 20px;
    }
    .placeorder-completion .heading{
        text-align: center;
        padding: 0 1rem;
    }
    .placeorder-completion .contact-detail-btn{
      height: 15px;
      width: 150px;
      border-radius: 20px;
      background-color: #800020;
   }
   .placeorder-completion .review-complete-btn{
    height: 15px;
    width: 150px;
    border-radius: 20px;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  
  }
  .placeorder-completion .form-group label {
    display: block;
    width: fit-content;
    font-size: 14px;
    margin-bottom: 4px;
  }
  .placeorder-completion .contact-details{
    max-width: 700px;
    margin: 0 auto;
    padding: 30px 40px;
    margin-top: 30px;
    
}
}
@media screen and (max-width:400px){
    .placeorder-completion .contact-details{
        max-width: 700px;
        margin: 0 auto;
        padding: 30px 30px; 
    }
    .placeorder-completion .order-active{
        margin-bottom: 20px;
    }
    .placeorder-completion .completion-detail-div{
        width: 100px;
    }
    
  
}
@media screen and (max-width:350px){
    .placeorder-completion .completion-detail-div{
        width: 95px;
    }
    .placeorder-completion .contact-details{
        max-width: 700px;
        margin: 0 auto;
        padding: 30px 20px;
        margin-top: 30px;
        
    }
    .placeorder-completion .heading{
        text-align: center;
        padding: 0 0.5rem;
    }
  }
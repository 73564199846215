.registerPage {
  width: 100%;
  min-height: calc(100vh - 132.97px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.registerPage .leftReg {
  width: 50%;
  min-height: calc(100vh - 132.97px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.registerPage .leftReg img {
  width: 90%;
  height: 90%;
  background-size: cover;
  border-radius: 5px;
}

.registerPage .rightReg {
  width: 50%;
  min-height: calc(100vh - 132.97px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.registerPage .rightReg .box {
  width: 100%;
  height: 50%;
  position: absolute;
  left: 0;
}

.registerPage .rightReg .innerRegister {
  width: 375px;
  flex-direction: column;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 1;
  border-radius: 10px;
}

.registerPage .rightReg .innerRegister .upNav {
  width: 100%;
  align-self: flex-start;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  padding: 20px 0;
}

.registerPage .rightReg .innerRegister .upNav a {
  color: #3a3a3a;
  margin: 0 20px;
  text-align: center;
}

.registerPage .rightReg .innerRegister .upNav a.active {
  color: #000000;
  text-decoration: none;
  position: relative;
}

.registerPage .rightReg .innerRegister .upNav a.active::after {
  width: 80%;
  margin: 0 auto;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -10px;
  height: 2px;
  background-color: #800020;
}

@media only screen and (max-width: 790px) {
  .registerPage .leftReg {
    display: none;
  }

  .registerPage .rightReg {
    width: 100%;
  }
}

@media only screen and (max-width: 790px) {
  .registerPage .rightReg .innerRegister{
    width: 95%;
  }
}


.homeContainer .contactBox {
  width: 100%;
  background-color: #1f1f1f;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  padding: 40px 0;
}

.homeContainer .contactBox .boxCont {
  margin: 40px 10px;
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.homeContainer .contactBox .boxCont .box {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.homeContainer .contactBox .boxCont .box img {
  width: 300px;
}

.homeContainer .contactBox .boxCont .box form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.homeContainer .contactBox .boxCont .box form input,
.homeContainer .contactBox .boxCont .box form textarea {
  width: 90%;
  margin: 10px 0;
  padding: 10px 15px;
  border-radius: 5px;
  border-style: none;
  font-size: 1.1rem;
  resize: none;
  color: #1f1f1f;
  border: 2px solid white;
}

.homeContainer .contactBox .boxCont .box form textarea {
  height: 100px;
}

.homeContainer .contactBox .boxCont .box form input:focus,
.homeContainer .contactBox .boxCont .box form textarea:focus {
  outline: none;
  border-color: #800020;
}

.homeContainer .contactBox .boxCont .box form button {
  width: 100%;
  margin-top: 40px;
  padding: 10px 15px;
  border-radius: 5px;
  border-style: none;
  font-size: 1.1rem;
  resize: none;
  color: #fff;
  font-size: 550;
  background-color: #800020;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.homeContainer .contactBox .boxCont .box form button:hover {
  background-color: #a0032a;
}

@media only screen and (max-width: 1028px) {
  .homeContainer .contactBox .boxCont {
    width: 100%;
  }
}

@media only screen and (max-width: 574px) {
  .homeContainer .contactBox .boxCont {
    width: 100%;
    justify-content: center;
    flex-direction: column;
    gap: 50px;
  }

  .homeContainer .contactBox .boxCont .box {
    align-items: center;
    text-align: center;
    width: 90%;
  }

  .homeContainer .contactBox .boxCont .box img {
    width: 250px;
  }
}

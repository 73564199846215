/* ReviewStep.css */

.container {
  max-width: 800px;
  margin: 0 auto;
}

.form-container {
  padding: 20px;
}

.form-section {
  margin-bottom: 20px;
}

.section {
  margin-bottom: 20px;
}

.section-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 27px;
  margin-bottom: 10px;
  margin-top: 2px;
  color: #800020;
  font-family: "Inter", Arial, sans-serif;
}
strong {
  font-weight: 500;
}

.amount-title {
  font-weight: 500;
  color: #fff;
  margin-bottom: 25px;
  font-size: 28px;
  line-height: 33.89px;
}

.section-title a {
  font-size: 20px;
  color: black;
}

.information-container {
  background-color: #f9f9f9;
  padding: 10px;
  border: 1px solid #800020;
  padding-left: 40px;
  padding-right: 40px;
}

.amount-information-container {
  background-color: black;
  padding: 25px;
  color: white;
}

.information-container div {
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 21.78px;
}

.amount-information-container div {
  display: flex;
  justify-content: space-between;
  font-family: "Inter", Arial, sans-serif;
  font-size: 23px;
  line-height: 27.04px;
}

.information-container div strong {
  display: inline-block;
}

.total-amount-div {
  background-color: #d9d9d9;
  padding: 10px;
  color: #000;
  font-family: "Inter", Arial, sans-serif;
}
.navigate-container {
  display: flex;
  justify-content: space-between;
}

.navigation-container {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.review-step-type-button,
.review-step-type-submit {
  background-color: #800000;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  align-self: center;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 24.2px;
  width: 180px;
  padding: 10px 0;
  transition: all 0.2s ease-in;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}

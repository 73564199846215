.parent{
    margin: 0;
    padding: 0;
    background-color: rgba(212, 212, 212, 1);
}
.parent .upper{
    height: 290px;
    background-color: rgba(31, 31, 31, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 90px;
}
.parent .upper .upper-heading{
    /* height: 200px; */
    height: auto;
    width: 400px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
}
.parent .upper .upper-heading h1{
    letter-spacing: 2.1px;

}
.parent .upper .upper-heading p{
    text-align: center;
    font-size: 12px;
    letter-spacing: 1.2px;
    font-weight: 500;
}
.parent .upper .images{
    display: flex;
    gap: 8px;
}
.parent .upper .images .photo1{
    height: 290px;
    padding: 0 10px;
}
.parent .upper .images .photo2{
    height: 290px;
    padding: 0 10px;
}

.parent .second-upper{
    height: 440px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 100px;
    max-width: 100vw;
}
.parent .second-upper .left{
    width: 600px;
    background-color: white;
    height: 440px;
    margin: 0;
    padding: 0;
}
.parent .second-upper .left .lg-img{
    position: relative;
    height: 340px;
    top: 35px;
    left: 80px;
}
.parent .second-upper .left .sm-img{
    position: relative;
    height: 180px;
    top: 75px;
}
.parent .second-upper .right{
    width: 650px;
}
.parent .second-upper .right h1{
    text-align: center;
    width: 400px;
    padding-left: 50px;
    padding-bottom: 9px;
}
.parent .second-upper .right p{
    display: inline-block;
    width: 400px;
    text-align: center;
    padding-left: 50px;
}
.parent .middle-bg{
    position: relative;
    background-color: black;
}
.parent .middle::before{
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0.4;
    background-image: url('./bgImg.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    /* height: 350px; */
}
.parent .middle{
    position: relative;
    width: 100%;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
}
.parent .middle h1{
    text-align: center;
    padding: 12px 0;
    color: white;
    font-weight: 400;
    letter-spacing: 2px;
}
.parent .middle .boxes{
    display: flex;
    max-width: 1360px;
    gap: 36px;
    align-items: center;
    justify-content: center;
}
.parent .middle .boxes .box{
    position: relative;
    height: 157px;
    width: 300px;
    background-color: white;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10px 20px;
    box-sizing: border-box;
}
.parent .middle .boxes .box img{
    height: 40px;
    margin-bottom: 10px;
}
.parent .middle .boxes .box h5{
    font-size: 17px;
    margin-bottom: 8px;
}
.parent .middle .boxes .box p{
    font-size: 13px;
}
.parent .plain-box{
    height: 250px;
    background-color: rgba(128, 0, 32, 1);
    display: flex;
    gap: 100px;
    justify-content: center;
    align-items: center;
    margin-top: 14px;
}
.parent .plain-box .text-area{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
}
.parent .plain-box .text-area h1{
    color: white;
    font-weight: 400;
}
.parent .plain-box .text-area p{
    color: white;
}
.parent .plain-box button{
    background-color: white;
    font-size: 16px;
    padding: 18px;
    border: none;
    border-radius: 6px;
    font-weight: 600;
}

.parent .stats{
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
    padding: 70px 0;
}
.parent .stats .heading{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    padding: 10px 60px;
}
.parent .stats .heading h1{
    color: rgba(128, 0, 32, 1);
    font-size: 3rem;
}
.parent .stats .heading p{
    font-size: 15px;
    width: 800px;
    text-align: center;
}
.parent .stats .box-sec{
    height: 150px;
    width: 240px;
    background-color: white;
    border-radius: 10px;
}
.parent .stats .box-sec .number{
    font-size: 46px;
    text-align: center;
    padding: 19px 2px;
    box-sizing: border-box;
    font-weight: 550;
}
.parent .stats .box-sec .number span{
    color: rgba(128, 0, 32, 1);
}
.parent .stats .box-sec .para{
    text-align: center;
    font-size: 10px;
}
.parent .eighthHomeCont {
    /* width: 100%; */
    /* max-width: 1400px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    color: black;
    text-align: center;
}  
.parent .eighthHomeCont img {
    width: 140px;
    margin: 30px;
    cursor: pointer;
}
@media only screen and (max-width:1166px) and (min-width:1066px){
    .parent .second-upper .left .sm-img{
        position: relative;
        left: 19px;
        top: 72px;
        height: 150px;
    }
}
@media only screen and (max-width:1210px) and (min-width:1056px){
    .parent .second-upper .left .sm-img{
        position: relative;
        left: 19px;
        top: 72px;
        height: 150px;
    }
}
@media only screen and (max-width:1056px) and (min-width:700px){
    .parent .second-upper .left .lg-img{
        height: 300px;
    }
    .parent .second-upper .left .sm-img{
        position: relative;
        left: 320px;
        top: -90px;
        height: 150px;
    }
    .parent .second-upper .right h1{
        width: 300px;
    }
    .parent .second-upper .right p{
        width: 300px;
    }
    .parent .upper .images img{
        height: 200px;
        width: 156px;
    }
    .parent .middle .boxes .box{
        height: 140px;
        width: 200px;
    }
}
@media only screen and (max-width:700px){
    .parent .upper{
        position: relative;
        flex-direction: column-reverse;
    }
    .parent .upper .upper-heading{
        position: absolute;
        /*left: 200px; */
        height: 240px;
        width: 300px;
        top: 188px;
        background-color: rgba(128, 0, 32, 1);
        color: white;
    }
    .parent .upper .images{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .parent .second-upper{
        margin: 230px 0;
        flex-direction: column;
        gap: 170px;
    }
    .parent .second-upper .left{
        background-color: transparent;
    }
    .parent .second-upper .left .sm-img{
        position: relative;
        height: 180px;
        top: 75px;
    }
    .parent .second-upper .right{
        width: auto;
    }
    .parent .second-upper .right h1{
        padding-left: 8px;
    }
    .parent .second-upper .right p{
        text-align: center;
        /* width: 300px; */
        padding: 0;
    }
    .parent .middle{
        height: 760px;
    }
    .parent .middle .boxes{
        flex-direction: column;
    }
    .parent .plain-box{
        display: none;
    }
    .parent .stats{
        height: 700px;
    }
    .parent .stats .heading p{
        width: auto;
    }
    .parent .stats .heading h1{
        text-align: center;
    }
    .parent .stats .boxes-sec{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .parent .stats .box-sec{
        padding: 10px 10px;
        width: 280px;
    }
    .parent .eighthHomeCont{
        display: none;
    }
}
@media only screen and (max-width:490px){
    .parent .upper{
        position: relative;
        flex-direction: column-reverse;
    }
    .parent .upper .upper-heading{
        padding: 30px 20px;
        height: auto;
        width: 260px;
        /* left: 60px; */
        top: 188px;
    }
    .parent .upper .images .photo1,.photo2{
        width: 115px;
    }
    .parent .second-upper{
        /* margin: 150px 40px; */
    }
    .parent .second-upper .left{
        width: auto;
        height: auto;
    }
    .parent .second-upper .left .lg-img{
        /* position: absolute; */
        width: 280px;
        height: 290px;
        left: 0px;
        /* top: 500px;
        right: 200px;
        left: 0; */
    }
    .parent .second-upper .left .sm-img{
        position: absolute;
        top: 790px;
        /* left: 240px; */
        width: 100px;
        height: 100px;
        left: 250px;
    }
    .parent .boxes-sec .box{
        height: 156px;
        width: 280px;
        background-color: white;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12), 0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12), 0 16px 16px rgba(0, 0, 0, 0.12);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0px 35px;
        box-sizing: border-box;
        gap: 16px;
    }
    .parent .second-upper .right{
        width: 300px;
    }
    .parent .second-upper .right p{
        width: 300px;
    }
    .parent .second-upper .right h1{
        width: 300px;
    }
    .parent .stats{
        height: 838px;
    }
}
